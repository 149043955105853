import * as React from 'react'

import { Box, Container, Grid, Link, Stack, Typography } from '@mui/material'

import type { TypographyProps } from '@mui/material'

const TERMS_AND_CONDITIONS_URL = process.env.GATSBY_TERMS_AND_CONDITIONS_URL
const PRIVACY_POLICY_URL = process.env.GATSBY_PRIVACY_POLICY_URL

const FooterText = ({
  align = 'justify',
  children,
}: TypographyProps) => (
  <Typography
    align={align}
    component='div'
    variant='caption'
    gutterBottom
  >
    {children}
  </Typography>
)

export const Footer = () => (
  <Box
    sx={{
      bgcolor: 'grey.900',
      color: 'grey.400',
    }}
  >
    <Container
      maxWidth='lg'
      sx={{
        display: 'flex',
        pt: 3,
        pb: 2,
      }}
    >
      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          xs={12}
          md={3}
        >
          <Stack
            spacing={1}
            sx={(theme) => ({
              textAlign: 'center',
              [theme.breakpoints.up('md')]: {
                textAlign: 'left',
              },
            })}
          >
            <Link
              href={TERMS_AND_CONDITIONS_URL}
              target='_blank'
              rel='noopener'
              color='inherit'
              underline='none'
              fontWeight={500}
            >
              Términos y condiciones
            </Link>
            <Link
              href={PRIVACY_POLICY_URL}
              target='_blank'
              rel='noopener'
              color='inherit'
              underline='none'
              fontWeight={500}
            >
              Política de privacidad
            </Link>
            <Link
              href='/denuncias'
              color='inherit'
              underline='none'
              fontWeight={500}
            >
              Canal de denuncias
            </Link>
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          md={9}
        >
          <FooterText>
            Senna SpA y sus Filiales
            son socios estratégicos con su broker
            en la gestión de remesas y servicios de asesoría. Al abrir una cuenta
            con Fugiro y optar por invertir, podrás acceder a la plataforma de nuestro broker,
            que te permite comprar y vender ETFs en
            la Bolsa de Comercio de Santiago y en un broker dealer en Estados Unidos.
            No somos intermediarios de valores ni custodios de tus inversiones; esa
            función la desempeña nuestro broker u otros
            intermediarios autorizados. Senna SpA y sus Filiales operan como asesores
            en servicios de remesas asociados a otras plataformas de brokerage establecidas
            y normadas según la Ley de Mercado de Valores y delega completamente la labor de intermediario
            y custodio de inversiones a este último. En Senna SpA y sus filiales,
            nos comprometemos a brindarte un servicio de calidad y a mantenerte
            informado/a sobre tus inversiones y remesas de manera transparente y segura.
          </FooterText>
          <FooterText align='center'>
            Cualquier duda adicional escríbenos a contacto@fugiro.capital
          </FooterText>
          <FooterText align='center'>
            &copy; Fugiro {new Date().getFullYear()}
          </FooterText>
        </Grid>
      </Grid>
    </Container>
  </Box>
)

export default Footer
